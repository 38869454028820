import React, { useEffect } from 'react'
import Link from 'next/link';
import { DoorStaff } from '../src/pages/Checkin'
import { TicketDriverLogo, TaoGroupLogo, LoginHeader } from '@venuedriver/ui-driver'

import { useAuthenticate } from '../src/hooks/contextHooks/useAuthenticate'
import { useRouter } from 'next/router';
import { useAuth } from '../src/hooks/contextHooks/useAuth'

import styles from './index.module.css'

const Index = () => {
  const router = useRouter();
  const { isLoading, isAuthenticated } = useAuth();
  const { authState: { role } } = useAuthenticate();

  if (isAuthenticated && role === 'staff') {
    return <DoorStaff />;
  }

  if (isAuthenticated) {
    router.push('/home');
  }

  // if (isLoading) {
  //   return <h3 style={{ color: 'white' }}>Loading...</h3>;
  // }

  return (
    <div className={styles.container}>
      <div className={styles['sub-container']}>
        <header className={styles.header}>
          <div className={styles['tao-group-logo-container']}>
            <TaoGroupLogo variant="sm" color="white" />
          </div>
          <p className={styles['login-link']}>
            <Link href={'auth/login'}>
              Login
            </Link>
          </p>
        </header>
        <section className={styles.body}>
          <div className={'animate__animated animate__pulse animate__repeat-2 animate__delay-2s'}>
            <TicketDriverLogo color="white" variant="large" size="1.2" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default Index
